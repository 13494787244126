.form-wrapper {
  margin: 100px auto;
  width: 1000px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 10px;
  height: 500px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

/* 
.close-btn {
  position: absolute;
  top: 2%;
  right: 3%;
  font-size: 1.5rem;
  z-index: 1;
  color: #fff;
  cursor: pointer;
} */

.form-content-left {
  background: linear-gradient(
    90deg,
    rgb(39, 176, 255) 0%,
    rgb(0, 232, 236) 100%
  );
  border-radius: 10px 0 0 10px;
  position: relative;
}

.form-img {
  width: 80%;
  height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-img-2 {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-success {
  text-align: center;
  font-size: 24px;
  margin-top: 40px;
  color: #fff;
}

.form-content-right {
  border-radius: 10px 0 0 10px;
  position: relative;
  background: linear-gradient(
    90deg,
    rgba(205, 143, 92, 1) 0%,
    rgba(230, 162, 94, 1) 50%,
    rgba(233, 197, 166, 1) 100%
  );
}

.form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form h1 {
  font-size: 1rem;
  text-align: center;
  width: 80%;
  margin-bottom: 1rem;
  color: #fff;
}

.form-inputs {
  margin-bottom: 0.5rem;
  width: 80%;
}

.c-section__title {
  padding: var(--s2) var(--s3) calc(var(--s6) * 1.8);
  margin: 0 calc(var(--s3) * -1);
  color: #cd8f5c;
  font-size: var(--s4);
  font-weight: 900;
  font-size: 3.5rem;
  letter-spacing: normal;
  text-align: center;
  letter-spacing: 0.3ch;
}

@media only screen and (max-width: 768px) {
  .c-section__title {
    font-size: 2.7rem;
  }
}

.form-inputs p {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  color: #f00e0e;
}

.form-label {
  display: inline-block;
  font-size: 0.8rem;
  margin-bottom: 6px;
  color: #fff;
}

.form-input {
  display: block;
  padding-left: 10px;
  outline: none;
  border-radius: 22px;
  height: 40px;
  width: 100%;
  border: none;
}

.form-input::placeholder {
  color: #595959;
  font-size: 12px;
}

.form-input-btn {
  width: 80%;
  height: 50px;
  margin-top: 10px;
  border-radius: 22px;
  background: #fff;
  outline: none;
  border: none;
  color: #000;
  font-size: 1rem;
}

.form-input-btn:hover {
  cursor: pointer;
  color: #fff;
  background: #000;
  transition: all 0.4s ease-in-out;
}

.form-input-login {
  font-size: 0.8rem;
  margin-top: 10px;
  color: #fff;
  width: 80%;
  text-align: center;
}

.form-input-login a {
  text-decoration: none;
  color: #27cdff;
  font-weight: 600;
}
.form div {
  font-size: 1rem;
  text-align: center;
  width: 80%;
  margin-top: 5px;
  color: #fff;
}
