.hero {
  margin-top: -80px;
  height: 750px;
  background-image: url("../../../images/hero1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
.hero .overlay {
  background-color: #000;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0.5;
}
.hero .content {
  color: #fff;
  z-index: 2;
  text-align: center;
}
.hero .content h1 {
  font-size: 45px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}
.hero .content p {
  font-family: "Montserrat", sans-serif;
}
