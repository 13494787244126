.containerr {
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
  padding-top: 6%;
  height: 100%;
  vertical-align: center;
}
.containerr ul li {
  width: 150px;
  text-align: center;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 150px;
  display: inline-block;
  text-decoration: none;
  text-decoration: none;
  border-radius: 41px;
  background: #fff;
  box-shadow: 5px -3px 5px rgb(204, 197, 197), -3px 3px 5px rgb(204, 197, 197);
  margin: 15px;
}

.containerr ul li:hover {
  background: #fff;
  cursor: pointer;
  box-shadow: inset -3px 3px 5px rgb(148, 148, 148),
    inset 3px -3px 5px rgb(255, 255, 255);
  transition: all 0.4s ease-in-out;
}
.containerr ul li i {
  color: #cd8f5c;
  font-size: 35px;
  padding-top: 55px;
  text-decoration: none;
}
