* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Poppins;
}
@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
